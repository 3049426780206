.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

input[type="file"] {
    display: none;
}

.img-preview {
    max-width: 80%;
    max-height: 80%;
    text-align: center;
}

.blue-circle {
    background-color: #fff;
    border-radius: 50%;
    display: inline-block;
    color: white;
    background-color: #007bff;
    text-align: center;
    font-size: 20px;
    width: 30px;
    height: 30px;
}