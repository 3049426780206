.section-title{
    background-color: blueviolet;
    color: #FFFFFF;
    padding: 4px;
    border-radius: 10px;
    margin-top: 15px;
}

.section{
    background-color: #f5f5f5;
    
}

.section-container{
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
}

/* pill format error msg, white text red background border radius */
.error{
    color: #FFFFFF;
    background-color: red;
    border-radius: 10px;
    width: 100%;
    margin: 5px;
}


/*
position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
*/
.modal-content{
    width: 396px;
    height: 96px;
    background-color: #f5f5f5;
    
}

.backgroud-color{
    background-color: #f5f5f5;
}

.destinatarioCard{
    border: 1px solid #ccc;
    padding: 0.25rem;
}