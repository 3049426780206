.tareas-container {
    background-color: #f5f5f5;
    height: 100vh;
  }

.yellow-chip{
  background-color: #dfaa52;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  display: inline-block;
  margin-right: .5rem;
}

.green-chip{
  background-color: #36838d;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  margin-right: .5rem;
}

.red-chip{
  background-color: #5d6b74;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  display: inline-block;
  margin-right: .5rem;
}

.grey-chip{
  background-color: #970e16;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  display: inline-block;
  margin-right: .5rem;
}

.encabezado-tareas{
  font-size: 0.8rem;
}

.tareasDetails > div > div > span{ 
  text-align: start !important;
}

.filterLabel{
  font-size: 14px;
}

.error-container{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #970e16;
}