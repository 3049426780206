.top-bar{
    background-color: #f2f2f2;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    box-shadow: 0 1px 0 rgba(0,0,0,.1);
    
    margin: 0 auto;
    padding: 0;

    width: 100vw;
    max-width: 100vw;
    top: 0;
    left: 0;
}

.logo-container{
    float: left;
    height: 40px;
    line-height: 40px;
    margin: 0;
    padding: 0;
    position: relative;
    width: auto;
}

.topBarLogo{
    height: 40px;
    margin: 0;
    padding: 0;
    position: relative;
    width: auto;
}

.userNameMsg{
    color: #4A148C;
    margin-left: 10px;
}

.icon-style{
    margin-left: 10px;
}

.logout-button-hidden{
    display: none;
}

.logout-button{
    position: absolute;
    left: 10px;
    top: 45px;
}