.login-logo{
    max-width: 60%;
    margin-left: 15%;
    margin-right: 15%;
    height: 40%;
}

.login-button{
    /* very dark purpel color*/
    background-color: #4A148C;
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 10px;
}

.login-container{
    background-color: #f5f5f5;
    height: 100vh;
    width: 100vw;
}