.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@font-face {
  font-family: SanofiScript-Regular;
  src: url('assets/fuentes/SanofiScript-Regular.otf');
}
@font-face {
  font-family: AvanrGrade-Regular;
  src: url('assets/fuentes/ITCAvantGardeStd-Bk.otf');
}
body {
  height: auto;
  max-width: 414px;
  font-family: 'Montserrat', sans-serif;
  color: #444;
  background-image: url(assets/imgs/bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

/********** Home **************/
.fa-user{
  color: #525ca3;
  font-size: 1.6rem;
  line-height: 2rem;
}
.lila{
  color: #525ca3;
}
h6 {
  font-size: 1rem;
  font-weight: 300;
  line-height: 2rem;
  font-family: AvanrGrade-Regular;
}
.empresa{
  font-size: 1.7rem;
  letter-spacing: 2px;
  font-weight: 500;
  line-height: 2rem;
}
.btnRealizarPedido, .btnSeguirPedido {
	box-shadow: 2px 3px 0px 0px #899599;
	background:linear-gradient(to bottom, #ededed 5%, #bab1ba 100%);
	background-color:#ededed;
	border-radius:16px;
	border:1px solid #d6bcd6;
	display:inline-block;
	cursor:pointer;
	color:#000000;
  font-family: SanofiScript-Regular;
	font-size: 1.4rem;
	font-weight:bold;
	padding:28px 8px;
	text-decoration:none;
	text-shadow:0px 1px 0px #e1e2ed;
  margin-top: 10%;
  margin-bottom: 10%;
  width: 220px;
  height: 220px;
  align-items: center;
  justify-content: center;
}
.btnRealizarPedido:hover, .btnSeguirPedido:hover {
	background:linear-gradient(to bottom, #bab1ba 5%, #ededed 100%);
	background-color:#bab1ba;
}
.btnRealizarPedido:active, .btnSeguirPedido:active {
	position:relative;
	top:1px;
}
a:hover {
  text-decoration: none;
  color: #525ca3;
}
.imgPedido{
  min-width: 6.5em;
  width: 6.5em;
}
/********** FIN Home **************/

/********** Realizar Pedidos **************/
.bg-sanofi{
  background-color: #525ca3 !important;
}
.titulo{
  font-size: 1.6rem;
  font-family: SanofiScript-Regular;
  letter-spacing: 2px;
  text-align: center;
  color: #fff;
  padding-top: 10%;
}
.lblTurno{
  font-weight: normal !important;
}
.subtitulo{
  font-size: 1.2rem;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 1px;
  text-align: center;
  color: #fff;
  padding-top: 2%;
}
input::placeholder{
  font-size: .9rem;
  font-style: italic;
}
textarea::placeholder{
  font-size: .9rem;
  font-style: italic;
}
.subtitulo2{
  font-size: .9rem;
  font-style: italic;
}
.lblDia{
  font-size: 1.1rem;
  font-weight: 600;
}
.lblHorario{
  font-size: 0.95rem;
  line-height: 2rem;
}
.btn-agregarDestinatario{
  background-color: #bba77d;
  border-radius: 16px;
  color: #fff;
  font-size: .9rem;
  padding: 10px 10px;
  width: 50%;
}
.btn-agregarDestinatario:hover {
  background-color: #706348;
  color: #fff;
}
.btn-confirma {
	box-shadow:inset 0px 1px 0px 0px #427a5d;
	background:linear-gradient(to bottom, #05ab55 5%, #427a5d 100%);
	background-color:#05ab55;
	border-radius:6px;
	border:1px solid #05ab55;
	display:inline-block;
	cursor:pointer;
	color:#fff;
	font-size:15px;
	font-weight:bold;
	padding:6px 24px;
	text-decoration:none;
	text-shadow:0px 1px 0px #037a3e;
}
.btn-confirma:hover {
	background:linear-gradient(to bottom, #427a5d 5%, #05ab55 100%);
	background-color:#427a5d;
  color: #fff;
}
.btn-confirma:active {
	position:relative;
	top:1px;
}

.btn-limpia {
  box-shadow:inset 0px 1px 0px 0px #ffffff;
	background:linear-gradient(to bottom, #ededed 5%, #dfdfdf 100%);
	background-color:#ededed;
	border-radius:6px;
	border:1px solid #dcdcdc;
	display:inline-block;
	cursor:pointer;
	color:#777777;
	font-family:Arial;
	font-size:15px;
	font-weight:bold;
	padding:6px 24px;
	text-decoration:none;
	text-shadow:0px 1px 0px #ffffff;
}
.btn-limpia:hover {
	background:linear-gradient(to bottom, #dfdfdf 5%, #ededed 100%);
	background-color:#dfdfdf;
}
.btn-limpia:active {
	position:relative;
	top:1px;
}
.volver{
  color: #525ca3;
}
/********** FIN Realizar Pedidos **************/
.imgBox{
  width: 40%;
}
.notificaPedido{
  font-family: SanofiScript-Regular;
  font-size: 1.8rem;
  text-align: center;
  padding: 0% 10% 0% 10%;
}
.imgNotiPedido{
  width: 15%;
  margin-top: 15%;
  margin-bottom: 2%;
}
/********** Notifica Pedidos **************/

/********** FIN Notifica Pedidos **************/

/********** Confirma Pedidos **************/
.imgConfirma{
  width: 50%;
  margin-top: 20%;
}
.notiPedido{
  font-family: SanofiScript-Regular;
  font-size: 1.6rem;
  padding: 0% 20% 0% 20%;
  text-align: center;
}
.bg-verde{
  background-color: #067035;
}
.bg-rojo{
  background-color: #cb2240;
}
.nroPedido{
  font-size: 1.6rem;
  font-weight: 800;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 1px;
  text-align: center;
  color: #fff;
  padding: 5% 0% 5% 0%;
}
/*floating version label*/
.version-label{
  position:fixed;
  bottom:0;
  right:0;
  width:10%;
  height:30px;
  background-color:rgba(0,0,0,0.5);
  color:#fff;
  text-align:center;
  line-height:30px;
  font-size:12px;
  font-weight:bold;
  z-index:9999;
  pointer-events: none;
}

.btn-masPedido{
  background-color: #bba77d;
  border-radius: 16px;
  color: #fff;
  font-size: .9rem;
  padding: 10px 10px;
  width: 50%;
}
.btn-masPedido:hover {
  background-color: #706348;
  color: #fff;
}
.verde{
  color: #067035;
}
.rojo{
 color: #cb2240;
}
/********** FIN Confirma Pedidos **************/

/********** Confirma Entrega **************/
.imgNoti{
  width: 25%;
  margin-top: 15%;
}
.notiEntrega{
  font-family: SanofiScript-Regular;
  font-size: 1.8rem;
  text-align: center;
  padding: 0% 30% 0% 30%;
}
.nroEntrega{
  font-size: 1.4rem;
  font-weight: 800;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 1px;
  text-align: center;
  color: #fff;
  padding: 2% 0% 2% 0%;
}
.eti {
  font-size: 0.7rem;
  font-weight: 800;
  width: min-content;
}
.fs-italic{
  font-style: italic;
}
/********** FIN Confirma Entrega **************/

/********** No Entrega **************/
.btn-verFoto{
  background-color: #cb2240;
  border-radius: 16px;
  color: #fff;
  font-size: .9rem;
  padding: 10px 10px;
  width: 50%;
}
.btn-verFoto:hover {
  background-color: #6e1e2c;
  color: #fff;
}
/********** FIN No Entrega **************/

/********** Pedidos **************/
.iconoPedido{
  width: 15%;
}
.tituloP{
  font-size: 2.2rem;
  line-height: 2.2rem;
  font-family: SanofiScript-Regular;
  letter-spacing: 2px;
  color: #fff;
  padding: 1% 0% 1% 10%;
  align-items: center;
}
.idEntrega{
  font-size: 1rem;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 1px;
  text-align: left;
  color: #fff;
  padding: 2% 0% 2% 10%;
  width: "100%";
}
.btn-link:hover {
  color: #fff;
  text-decoration: none;
}
.btn.focus, .btn:focus {
  outline: 0;
  outline-color: transparent;
  outline-style: none;
  outline-width: 0px;
  box-shadow: 0 0 0 0 transparent;
}
.card-header:hover{
  background-color: #bba77d;
}
.btn-lg{
  border-radius: 0;
  font-size: 1rem;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
}
/********** FIN Pedidos **************/

/********** Trámites Pedidos **************/
.tituloT{
  font-size: 1.4rem;
  line-height: 1.4rem;
  font-family: SanofiScript-Regular;
  letter-spacing: 2px;
  color: #fff;
  padding: 1% 0% 1% 0%;
  align-items: center;
  text-align: center;
}
.tramiteR{
  font-family: SanofiScript-Regular;
  font-size: 1.8rem;
  text-align: center;
  padding: 0% 10% 0% 10%;
}
/********** FIN Tramites Pedidos **************/

/********** Tarea realizada y no realizada **************/
.btn-guardarComentario{
  background-color: #067035; 
  border-radius: 16px;
  color: #fff;
  font-size: .9rem;
  padding: 10px 10px;
  width: 50%;
}
.btn-guardarComentario:hover {
  background-color: #094624;
  color: #fff;
}

.btn-tomaFoto{
  background-color: #c7c7c7;
  border-radius: 16px;
  color: #fff;
  font-size: 2.4rem;
  padding: 10px 10px;
  width: 20%;
  height: 20%;
}
.btn-tomaFoto:hover {
  color: #000
}
option{
  font-size: .8rem;
}
/********** Fin tarea realizada y no realizada **************/